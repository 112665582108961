import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFlags } from 'redux/actions/featureFlags';
import { StatsigClient } from '@statsig/js-client';
import { APP_STASTIG_CLIENT_ID } from 'config/env-vars';
import { getCurrentUser, getCurrentUserLoading } from 'redux/selectors/users';

const StatsigGetFlags = () => {
  const currentUser = useSelector(getCurrentUser);
  const loading = useSelector(getCurrentUserLoading);
  const dispatch = useDispatch();
  const anonymousId = window.analytics.user
    ? window.analytics.user().anonymousId()
    : null;

  useEffect(() => {
    if (
      !anonymousId ||
      process.env.NODE_ENV === 'test' ||
      window.env.REACT_APP_CIRCLECI === 'true'
    ) {
      return;
    }

    if (!loading && loading !== undefined && currentUser) {
      const client = new StatsigClient(APP_STASTIG_CLIENT_ID, {
        userID: currentUser?.id,
        customIDs: {
          segmentAnonymousID: anonymousId,
          segmentAffiliation: currentUser?.affiliation,
        },
      });

      client.initializeSync();

      const checkoutPageClient = client.getExperiment('new_checkout_page');
      const checkoutValue = checkoutPageClient.get('page', 'Angular');
      dispatch(addFlags.request({ flags: { checkoutPage: checkoutValue } }));
    }
  }, [loading, currentUser]);

  return null;
};

export default memo(StatsigGetFlags);
