import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { getFlags, getLoading } from 'redux/selectors/featureFlags';
import { useSelector } from 'react-redux';
import PageSkeleton from './PageComponents/PageSkeleton';

const Error = lazy(() => import('pages/Error'));

const ExperimentRoute = () => {
  const { checkoutPage } = useSelector(getFlags);
  const loading = useSelector(getLoading);

  if (loading) {
    return <PageSkeleton />;
  }

  if (checkoutPage === 'React') {
    return <Outlet />;
  }

  return <Error />;
};

export default ExperimentRoute;
